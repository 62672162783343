@import 'styles/utils.scss';

.pop-icons-root{
    @extend .section-main;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .filter{
        @extend .section-main;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        background: #000000;
        opacity: 0.6;
        margin-top: 0;
        z-index: 1;
    }
    .title-container{
        z-index: 2;
        position: relative;
        margin-top: 120px;
        line-height: 5em;
        @include media-s(){
            font-size: 0.6em;
            line-height: 4.2em;
            margin-top: 150px;
        }
    }
    .icons-container{
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(50%, 50%);
        line-height: 0;
        margin: 0;
        z-index: 0;
        @include media-s(){
            display: none;
        }
        .icon{
            text-align: center;
            border-radius: 50%;
            background-color: #ffffff;
            padding: 37px 25px;
            font-size: 20px;
            position: absolute;
            box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
            font-weight: bold;
            color: #585858;
        }
        .icon.PHP{
            margin-top:-50px;
            margin-left: -270px;
            background: #AF860B;
            color: #6B5000
        }
        .icon.NODEJS{
            margin-top:-210px;
            margin-left: -270px;
            background: #E3A848;
            color: #915900
        }
        .icon.MYSQL{
            margin-top:-320px;
            margin-left: -110px;
            background: #AF9E0B;
            color: #786C00
        }
        .icon.MONGODB{
            margin-top:-150px;
            margin-left: 310px;
            background: #D99F43;
            color: #965C00;
            
        }
        .icon.HTML{
            margin-top:-300px;
            margin-left: 280px;
            background: #CB4F1D;
            color: #782200;
        }
        .icon.REACT{
            margin-top:-360px;
            margin-left: 90px;
            background: #B11951;
            color: #690027;
        }
        .icon.CORDOVA{
            margin-top:-30px;
            margin-left: 350px;
            background: #7A1585;
            color: #48014F;
        }
    }
    .bottom-arrow-container{
        position: absolute;
        bottom: 10px;
        left: 50%;
        z-index: 1;
        color: #ffffff;
        text-align: center;
        .bottom-arrow{
            font-size: 3rem;
            margin-left: -1.5rem;
        }
    }   
}