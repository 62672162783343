@import 'styles/utils.scss';

@keyframes fadeIn{
   0% { opacity: 0; }
   100% {opacity: 1;}
}

@keyframes fadeOut{
   0%{opacity: 1;}
   100%{opacity: 0; bottom: initial;}
}

.background{
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 11;
   &.enter{
      animation: fadeIn 0.25s ease-in both;
      display: block;
   }
   &.leave{
      animation: fadeOut 0.25s ease-in both;
      display: none;
   }
}
