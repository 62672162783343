@import 'styles/utils.scss';

.component_stagger-pannel-root{
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .pannel-divider{
        overflow: hidden;
        width: 100%;
        height: 100vh;
        position: relative;
        .pannel{
            width: 100%;
            height: inherit;
            // background: $secondary-color;
            border-right: 1px solid rgba(0,0,0,0.2);
            box-sizing: border-box;
            .text{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 1.5em;
                white-space: nowrap;
                letter-spacing: 0.2em;
                font-weight: bold;
                opacity: 0.2;
                line-height: 2rem;
                text-align: center;
                img{
                    height: 48px;
                }
            }
        }
    }
    &.is-mobile-view{
        flex-direction: column;
        .pannel-divider{
            height: 25vh;
            .pannel{
                border-bottom: 1px solid rgba(0,0,0,0.2);;
            }
        }
    }
}