@import 'styles/utils.scss';

.header-root{
    position: fixed;
    width: 100%;
    z-index:10;
    .header-container{
        height: 80px;
        display: flex;
        align-items: center;
        color: #ffffff;
        @include media-s(){
            height: 62px;
            justify-content: space-between;
        }
    }
    .pintest{
        height: 60px;
    }
    .logo-container{
        margin-left: 40px;
        .text-link{
            font-size: 22px;
        }
        img{
            display: inline-block;
            height: 1.2rem;
        }
        @include media-s(){
            margin-left: 20px;
        }
    }
    .menu-container{
        .menu-wrapper{
            display: flex;
            justify-content: flex-start;
            li{
                width: 172px;
                text-align: center;
            }
            a{
                padding: 28px 50px;
                font-size: 16px;
                text-transform: uppercase;
                &:hover:before, 
                &.active:before
                {
                    height: 5px;
                }
                &:before{
                    height: 0px;
                    width: 172px;
                    background: $secondary-color;
                    position: absolute;
                    display: block;
                    top:0;
                    content: '';
                    transition: all 0.1s linear;
                }
            }
        }
    }
    .mobile-menu-button{
        color: white;
        margin-right: 20px;
        display: none;
        .material-icons{
            color: #ffffff;
            font-size: 2rem;
        }
        @include media-s(){
            display: block !important;
        }
    }
    @include media-s(){
        .header-container{
            .logo-container{
                margin-left: 20px;
                .text-link{display: none;}
                img{
                    height: 2rem;
                }
            }
            .menu-container{
                display: none;
            }
        }
    }
}
