// body, 타이포그래피 등 기본 스타일 설정
@import url("//fonts.googleapis.com/earlyaccess/notosanskr.css");
@import url("//cdn.jsdelivr.net/gh/velopert/font-d2coding@1.2.1/d2coding.css");
@import url("//fonts.googleapis.com/css?family=Roboto:300,400,500");
@import url("//fonts.googleapis.com/icon?family=Material+Icons");
@import url(https://cdn.rawgit.com/openhiun/hangul/14c0f6faa2941116bb53001d6a7dcd5e82300c3f/nanumbarungothic.css);

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	// font-family: 'Nanum Barun Gothic', sans-serif;
	font-family: "Noto Sans KR", sans-serif; 
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a{
   text-decoration: none;
   color: inherit;
}


// body {
//    margin: 0;
//    box-sizing: border-box;
//    font-family: "Noto Sans KR", sans-serif;
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
//  }
  
//  // box-sizing 일괄 설정
//  * {
//    box-sizing: inherit;
//  }
  
//  // 링크 스타일 밑줄 및 색상 무효화
//  a {
//    text-decoration: inherit;
//    color: inherit;
//  }
 ul li {
    list-style-type: none
 }
 