@import './mixins.scss';

// clearfix
.clearfix:after{
    clear: both;
    display: block;
    content: '';
}

// 메인 컬러 테마
$secondary-color: #ff9547;

// about 페이지 컬러 테마
$page_about-color: #f7fe00;

//========== section
//최상단 섹션
.section-main{
    height: 768px;
    margin-top: -80px;
    @media screen and (max-width : 600px){
        height: 100vh;
    }
}
.section-l{
    width: 100%;
    padding-top: 200px;
    @media screen and (max-width : 600px){
        padding-top: 80px;
    }
}