@import 'styles/utils.scss';

#contents_fill-menu-root{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index:12;
    top: 0;
    left:0;
    background-color: #ffffff;
    opacity: 0.95;
    .top-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 62px;
        .close-button{
            margin-right: 20px;
            .material-icons{
                font-size: 2rem;
            }
        }
    }
    .menu-container{
        margin-top:20px;
        text-align: center;
        font-size: 2rem;
        line-height: 5rem;
        li{
            display: block;
            opacity:0;
            a.active{
                color: #aaaaaa;
            }
        }
    }
}
